/*****************************************************************************************
 * 설명 : 추천
 * URI : /
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { baseImgUrl } from 'config/config';
import * as moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import Restful from 'service/restful';

import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import NewYearPC from 'assets/images/recommend/year01_new.png';
import NewYearMobile from 'assets/images/recommend/year01m_new.png';
import ToJungPC from 'assets/images/recommend/year02_new.png';
import ToJungMobile from 'assets/images/recommend/year02m_new.png';

import "swiper/css";
import "swiper/scss/effect-fade";
import "swiper/scss/effect-flip";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Layout from 'pages/homepage/layout/layout';

import './recommend.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Recommend = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();

  // 공지사항
  const [notice, setNotice] = useState([]);
  const [best, setBest] = useState([]);
  const [recommend, setRecommend] = useState([]);

  /***************************************************************************************
   * 설명 : 공지사항 리스트 가져오기
  ***************************************************************************************/
  const getBoardList = () => {
    let params = {
      program: 'home',
      service: 'board',
      version: '1.0',
      action: 'getBoardList',
      boardId: 'notice',
      searchText: '',
      searchType: '',
      pageNo: 1,
      pageRow: 1
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setNotice(response.data.data);

      } else {
        setNotice([])
      }
    });
  }

  /***************************************************************************************
   * 설명 : 베스트 운세 가져오기
  ***************************************************************************************/
  const getBestList = () => {
    let params = {
      program: 'home',
      service: 'main',
      version: '1.0',
      action: 'getBestList',
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setBest(response.data.data);

      } else {
        setBest([])
      }
    });
  }

  /***************************************************************************************
   * 설명 : 추천 운세 가져오기
  ***************************************************************************************/
  const getRecommendList = () => {
    let params = {
      program: 'home',
      service: 'main',
      version: '1.0',
      action: 'getRecommendList',
    }

    getApi("get", params).then( response => {
      if( response !== undefined && response.data.result && response.data.data && response.data.data.length > 0 ) {
        setRecommend(response.data.data);

      } else {
        setRecommend([]);
      }
    });
  }

  /***************************************************************************************
   * 설명 : 데이터 로딩 시 처리
  ***************************************************************************************/
  useEffect(() => {
    getBoardList();

    getBestList();

    getRecommendList();

    // eslint-disable-next-line
  }, [])

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <Layout>
      <div className="recommend">
        <section className="main-menu mobile-only">
          <ul className="menu clearfix">
            <li><NavLink to="/" className={window.location.pathname === '/' ? 'depth1 on' : 'depth1'}>추천</NavLink></li>
            <li><NavLink to="/saju" className={window.location.pathname === '/saju' ? 'depth1 on' : 'depth1'}>사주·운세</NavLink></li>
            <li><NavLink to="/gunghap" className={window.location.pathname === '/gunghap' ? 'depth1 on' : 'depth1'}>애정운·궁합</NavLink></li>
            <li><a className ="depth1" href="https://smartstore.naver.com/sajuin" >쇼핑</a></li>
          </ul>
        </section>

        <section className="top-visual">
          <section className="info-div">
            <h1>내가 지금 제대로 가고 있는 걸까?</h1>
            <p className="txt">
              인생은 가보지 않은 길을 걷는 것과 같습니다. <br />
              네 기둥(<span className="otherChar">四柱</span>) 여덟 글자(<span className="otherChar">八字</span>) 속에 담긴<br className="mobile-only" /> 지도를 찾아보세요.
            </p>
            <div className="link-box">
              <div className="link-inner">
                <span className="free"></span>
                <span>타고난 나의 성격을 무료로 확인해 보세요!</span>
                <NavLink to="/service?serviceSeq=4" className="link-btn">바로가기</NavLink>
              </div>
              <div className="bg"></div>
            </div>
          </section>
        </section>

        <section className="section year-link">
          <div className="inner">
            <NavLink to="/service?serviceSeq=3" className="box">
              <div className="bg"></div>
              <img className="pc-only" src={NewYearPC} alt="신년운세" />
              <img className="mobile-only" src={NewYearMobile} alt="신년운세" />
            </NavLink>
            <NavLink to="/service?serviceSeq=1" className="box">
              <div className="bg"></div>
              <img className="pc-only" src={ToJungPC} alt="토정비결" />
              <img className="mobile-only" src={ToJungMobile} alt="토정비결" />
              {/* <h3 className="txt">토정비결<span>&nbsp;&nbsp;보러가기</span></h3> */}
            </NavLink>
          </div>
        </section>

        <section className="section why-link pc-only">
          <h2 className="section-title">왜 ‘사주인’인가?</h2>
          <div className="inner">
            {/* <NavLink to="/contents?seq=49" className="box"></NavLink> */}
            <NavLink to="/contents?seq=49" className="box">
              <div className="img img1"></div>
              <h4 className="box-title">80 : 20의 법칙</h4>
              <p className="txt">아무리 뛰어난 사람도 여덟 글자[八字] 뿐</p>
            </NavLink>
            <NavLink to="/contents?seq=50" className="box">
              <div className="img img2"></div>
              <h4 className="box-title">운기학에 기반한 이론체계</h4>
              <p className="txt">천간이 10개인데 지지가 12개인 이유는?</p>
            </NavLink>
            <NavLink to="/contents?seq=51" className="box">
              <div className="img img3"></div>
              <h4 className="box-title">엉터리는 이제 그만</h4>
              <p className="txt">식신食神이 ‘먹는 귀신’이라고?</p>
            </NavLink>
          </div>
        </section>

        <section className="section why-link mobile-only">
          <h2 className="section-title">왜 ‘사주인’인가?</h2>
          <Swiper
            className="why-slide"
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <div>
                <NavLink to="/contents?seq=49" className="box">
                  <div className="img img1"></div>
                  <h4 className="box-title">80 : 20의 법칙</h4>
                  <p className="txt">아무리 뛰어난 사람도 여덟 글자[八字] 뿐</p>
                </NavLink>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
              <NavLink to="/contents?seq=50" className="box">
                <div className="img img2"></div>
                <h4 className="box-title">운기학에 기반한 이론체계</h4>
                <p className="txt">천간이 10개인데 지지가 12개인 이유는?</p>
              </NavLink>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <NavLink to="/contents?seq=51" className="box">
                  <div className="img img3"></div>
                  <h4 className="box-title">엉터리는 이제 그만</h4>
                  <p className="txt">식신食神이 ‘먹는 귀신’이라고?</p>
                </NavLink>
              </div>

            </SwiperSlide>
          </Swiper>
        </section>

        <section className="section board">
          <div className="inner clearfix">
            <div className="board-title">새소식</div>
            <div className="board-txt">
              {notice.length < 1 &&
                '등록된 공지사항이 없습니다.'
              }
              {notice.length > 0 &&
                <NavLink to={"/board/view?boardId=" + notice[0].boardId + "&boardSeq=" + notice[0].seq + "&pageNo=1&pageRow=10"}>
                  {'[' + moment(notice[0].createDate).format('YYYY-MM-DD') + '] ' + notice[0].subject}
                </NavLink>
              }
            </div>
            <div className="icon1"><KeyboardArrowRightIcon /></div>
          </div>
        </section>

        <section className="section best-link">
          <h2 className="section-title">베스트 운세</h2>
          <div className="inner">
            {
              best.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className="box"
                    to={parseInt(item.servicesSeq) === 0 ? item.linkUrl : '/service?serviceSeq=' + item.servicesSeq }
                  >
                    <div className="img"><img src={baseImgUrl + "/api/file/download.php?seq=" + item.uploadFileSeq} alt={item.title} /></div>
                    <h4 className="box-title">
                      {parseInt(item.price) === 0 &&
                        <span className="free" />
                      }

                      {parseInt(item.isNew) === 1 &&
                        <span className="new" />
                      }

                      {item.title}
                    </h4>
                    <p className="txt">{item.summary}</p>
                  </NavLink>
                )
              })
            }
          </div>
        </section>

        <section className="section recommend-link">
          <h2 className="section-title">추천 운세</h2>
          <div className="inner">
            {
              recommend.map((item, index) => {
                return (
                  <NavLink key={index} className="box" to={parseInt(item.servicesSeq) === 0 ? item.linkUrl : '/service?serviceSeq=' + item.servicesSeq }>
                    <div className="img">
                      <img src={baseImgUrl + "/api/file/download.php?seq=" + item.uploadFileSeq} alt={item.title} style={{width: '100%'}} />
                    </div>
                    <div className="txt-area">
                      <h4 className="box-title">
                        {parseInt(item.price) === 0 &&
                          <span className="free" />
                        }
                        {item.title}
                      </h4>
                      <p className="txt">{item.summary}</p>
                    </div>
                  </NavLink>
                )
              })
            }
          </div>
        </section>

        <section className="section saju-link1">
          <div className="inner">
            <div className="box" onClick={() => navigate('/board?boardId=stars&pageNo=1&pageRow=20')}>
              <h4 className="box-title">유명인 사주</h4>
              <p className="txt">부와 명예, 대중들의 사랑과 관심을 한 몸에 받고 있는 유명인들은 어떤 사주를 가지고 있을까?</p>
              <NavLink to="/board?boardId=stars&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=dramas&pageNo=1&pageRow=20')}>
              <h4 className="box-title">드라마 사주</h4>
              <p className="txt">많은 사람들을 웃음짓고 눈물짓게 만든 감동의 드라마에서 논란과 이슈를 몰고 온 화제의 드라마까지, 잊을 수 없는 드라마 속 주인공들의 사주는 어떨까요?</p>
              <NavLink to="/board?boardId=dramas&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
            <div className="box" onClick={() => navigate('/board?boardId=contents&pageNo=1&pageRow=20')}>
              <h4 className="box-title">명리학 이야기</h4>
              <p className="txt">음양오행, 주역, 사주 명리, 풍수, 토정비결 등 그 뿌리가 수천년을 이어져 내려온 인류의 소중한 유산인 동양학에 대한 재미있고 유익한 정보를 확인해 보세요.</p>
              <NavLink to="/board?boardId=contents&pageNo=1&pageRow=20" className="link-btn">자세히 보기</NavLink>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Recommend;