/*****************************************************************************************
 * 설명 : 프로필 등록
 * URI : /member/profile
 * 작성자 :
 * 작성일 :
*****************************************************************************************/
import { Button, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import * as moment from 'moment';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";

import { timeList } from 'config/config';

import { showDialog } from 'reducers/dialogReducer';

import { InputEx, SelectEx } from 'components/inputEx';

import { Restful } from 'service/restful';

import 'pages/homepage/member/member.scss';

/*****************************************************************************************
 * 설명 : 함수 선언
*****************************************************************************************/
const Profile = ( props ) => {

  /***************************************************************************************
   * 설명 : 변수 선언부
  ***************************************************************************************/
  const [getApi] = Restful();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [queryString, setQueryString] = useState({});

  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [dayList, setDayList] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      sjms_sex: '남',
      sjms_year: '',
      sjms_month: '',
      sjms_day: '',
      sjms_hour: '',
      sjms_solunar: 'solar'
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(30, '30자').required('필수'),
      email: Yup.string().max(100, '100자').required('필수'),
    }),
    onSubmit: (values) => {
      let params = {
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setProfiles',
        ...values
      }

      getApi("post", params).then( response => {
        if( response !== undefined && response.data.result ) {
          dispatch(showDialog({header: '안내', message: response?.data?.message, click: () => navigate('/')}));

        } else {
          dispatch(showDialog({header: '에러', message: response?.data?.message || '서버와의 통신에 실패하였습니다.'}));
        }
      });
    }
  });

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    // 년도 계산
    let tmp = [];
    for(let i = moment().add(-99, 'year').format('YYYY'); i <= moment().format('YYYY'); i++ ) tmp.push({label: i + '년', value: i});
    setYearList(tmp);

    formik.setFieldValue('sjms_year', moment().add(-24, 'year').format('YYYY'));

    // 월 계산
    tmp = [];
    for(let i = 1; i <= 12; i++ ) {
      let tmp1 = (i < 10) ? '0' + i : i;
      tmp.push({label: tmp1 + '월', value: tmp1});
    }
    setMonthList(tmp);

    // 일 계산
    tmp = [];
    for(let i = 1; i <= 31; i++ ) {
      let tmp1 = (i < 10) ? '0' + i : i;
      tmp.push({label: tmp1 + '일', value: tmp1});
    }
    setDayList(tmp);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /***************************************************************************************
   * 설명 : 데이터 로딩 처리
  ***************************************************************************************/
  useEffect(() => {
    let query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    setQueryString(query);

    formik.setFieldValue('name', decodeURIComponent(query?.name ?? ''));
    formik.setFieldValue('email', decodeURIComponent(query?.email ?? ''));

    const loadGtagScript = () => {
      // gtag.js 스크립트를 동적으로 로드
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=AW-862730987`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        // 스크립트 로드 후 dataLayer와 gtag 함수 초기화
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-862730987');

        gtag('event', 'conversion', {'send_to': 'AW-862730987/QXWRCJjUu_UYEOv1sJsD'});
      };

      script.onerror = () => {
        console.error('Google Analytics 스크립트를 로드하는 데 실패했습니다.');
      };
    };

    loadGtagScript();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  /***************************************************************************************
   * 설명 : html 선언부
  ***************************************************************************************/
  return (
    <section className ="member-home bwhite">
      <form onSubmit={formik.handleSubmit}>
        <h1 className="page-title login-title">프로필 등록</h1>
        <div className="login-wrap">
          <div className="box">
            안녕하세요?<br />
            고객님께서
            {
              queryString?.sso === 'nv'
              ? ' 네이버로 '
              : queryString?.sso === 'kk'
              ? ' 카카오로 '
              : queryString?.sso === 'fb'
              ? ' 페이스북으로 '
              : queryString?.sso === 'gg'
              ? ' 구글로 '
              : ''
            }
            로그인하시더라도 <br />
            사주 간명을 위해서는 아래의 정보가 필요합니다.<br />
            지금 정확한 내용을 입력해두시면 저희 사주인의 서비스를 더욱 편리하게 이용하실 수 있습니다.
          </div>

          <label className="label mt20" htmlFor="name">이름</label>
          <InputEx
            name="name"
            formik={formik}
            fullWidth={true}
            className="inputbox"
            variant="outlined"
            placeholder="이름"
          />

          <label className="label mt20" htmlFor="email">이메일</label>
          <InputEx
            name="email"
            formik={formik}
            fullWidth={true}
            className="inputbox"
            variant="outlined"
            placeholder="이메일 주소"
            autoFocus
          />

          <label className="label mt20" htmlFor="sjms_sex">성별</label>
          <FormControl className="radio">
            <RadioGroup
              row
              defaultValue={'남'}
              name="sjms_sex"
              color="success"
              className="radio"
              onChange={formik.handleChange}
            >
              <FormControlLabel value={'남'} control={<Radio />} label="남" />
              <FormControlLabel value={'여'} control={<Radio />} label="여" />
            </RadioGroup>
          </FormControl>

          <label className="label mt20" htmlFor="sjms_year">생년월일</label>
          <div className="select-div">
            <SelectEx
              name="sjms_year"
              formik={formik}
              className="fl selectbox year"
              variant="outlined"
              data={[
                {label: '생년', value: ''}
              ].concat(yearList)}
              InputProps={{
                endAdornment: <InputAdornment position="end">년</InputAdornment>,
              }}
              style={{width: '33%'}}
            />

            <SelectEx
              name="sjms_month"
              formik={formik}
              className="fl selectbox ml10"
              variant="outlined"
              data={[
                {label: '월선택', value: ''}
              ].concat(monthList)}
              InputProps={{
                endAdornment: <InputAdornment position="end">월</InputAdornment>,
              }}
              style={{width: 'calc(33% - 10px)'}}
            />

            <SelectEx
              name="sjms_day"
              formik={formik}
              className="fl selectbox ml10"
              variant="outlined"
              data={[
                {label: '일선택', value: ''}
              ].concat(dayList)}
              InputProps={{
                endAdornment: <InputAdornment position="end">일</InputAdornment>,
              }}
              style={{width: 'calc(33% - 10px)'}}
            />
          </div>

          <div className="clearfix" />

          <label className="label mt20" htmlFor="sjms_hour">생시</label>
          <div className="select-div">
            <SelectEx
              name="sjms_hour"
              formik={formik}
              fullWidth={true}
              className="selectbox"
              data={[
                {label: '생시 선택', value: ''}
              ].concat(timeList)}
            />
          </div>

          <label className="label mt20" htmlFor="sjms_solunar">양/음력</label>
          <div className="select-div">
            <SelectEx
              name="sjms_solunar"
              formik={formik}
              fullWidth={true}
              className="selectbox"
              data={[
                {label: '양력', value: 'solar'},
                {label: '음력(평달)', value: 'lunar0'},
                {label: '음력(윤달)', value: 'lunar1'}
              ]}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            className="login-btn green mt30"
          >등록하기</Button>
        </div>
      </form>
    </section>
  );
}

/*****************************************************************************************
 * 설명 : default export 선언
*****************************************************************************************/
export default Profile;